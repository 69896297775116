import React, { Suspense } from "react";
import "./App.css";
import { Provider } from "react-redux";
import { Navigate } from "react-router-dom";
import { store } from "./Redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";

const AddNewJobAssignments = React.lazy(() =>
  import("./Components/AddNewJobAssignments")
);
const AdminLandingPage = React.lazy(() =>
  import("./Components/AdminLandingPage")
);
const AssistantLandingPage = React.lazy(() =>
  import("./Components/AssistantLandingPage")
);
const CompanyRegistration = React.lazy(() =>
  import("./Components/CompanyRegistration")
);
const CompanyList = React.lazy(() => import("./Components/CompanyList"));
const Invoices = React.lazy(() => import("./Components/Invoices"));
const JobAssignmentDashBoard = React.lazy(() =>
  import("./Components/JobAssignmentDashBoard")
);
const JobsList = React.lazy(() => import("./Components/JobsList"));
const LoginForm = React.lazy(() => import("./Components/LoginForm"));
const TrooperLandingPage = React.lazy(() =>
  import("./Components/TrooperLandingPage")
);
const TrooperList = React.lazy(() => import("./Components/TropperList"));

const ReviewTrooperInvoice = React.lazy(() =>
  import("./Components/ReviewTrooperInvoice")
);

const TrooperRegistrationForm = React.lazy(() =>
  import("./Components/RegisterTrooperForm")
);
const UnderConstruction = React.lazy(() =>
  import("./Components/UnderConstruction")
);
const Unauthorized = React.lazy(() => import("./Components/Unauthorized"));
const PageNotFound404 = React.lazy(() =>
  import("./Components/PageNotFound404")
);

const LoadingComponent = () => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      background: "linear-gradient(180deg, #000080 30%, #000000 90%)",
    }}
  >
    <CircularProgress sx={{ color: "white" }} />
  </Box>
);

const LazyWrapper = ({ Component, roles = ["default"], ...rest }) => {
  const userRole = localStorage.getItem("userRole"); // after login save the role under localstorage

  // Check if the user role is included in the roles array or if the role is "default"
  if (roles.includes("default") || roles.includes(userRole)) {
    return (
      <Suspense fallback={<LoadingComponent />}>
        <Component {...rest} />
      </Suspense>
    );
  } else {
    return <Navigate to="/unauthorized" />;
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000080",
    },
    base: {
      main: "#ffffff",
    },
    worker: {
      main: "#ff0000",
    },
    action: {
      active: "#000080",
    },
    action2: {
      active: "#087c3d",
    },
  },
});

function App() {
  const route = createBrowserRouter([
    {
      path: "/",
      element: <LazyWrapper Component={LoginForm} role={["default"]} />,
    },
    {
      path: "/register",
      element: (
        <LazyWrapper Component={TrooperRegistrationForm} roles={["default"]} />
      ),
    },
    {
      path: "/adminLandingPage",
      element: <LazyWrapper Component={AdminLandingPage} roles={["admin"]} />,
    },
    {
      path: "/assistantLandingPage",
      element: (
        <LazyWrapper Component={AssistantLandingPage} roles={["assistant"]} />
      ),
    },
    {
      path: "/trooperLandingPage",
      element: <LazyWrapper Component={TrooperLandingPage} roles={["user"]} />,
    },

    {
      path: "/companyRegistration",
      element: (
        <LazyWrapper
          Component={CompanyRegistration}
          roles={["admin", "assistant"]}
        />
      ),
    },
    {
      path: "/jobAssignmentDashBoard",
      element: (
        <LazyWrapper Component={JobAssignmentDashBoard} roles={["admin"]} />
      ),
    },
    {
      path: "/addNewJobAssignments",
      element: (
        <LazyWrapper Component={AddNewJobAssignments} roles={["admin"]} />
      ),
      },
    {
      path: "/trooperList",
      element: (
        <LazyWrapper Component={TrooperList} roles={["admin", "assistant"]} />
      ),
    },
    {
      path: "/companyList",
        element: <LazyWrapper Component={CompanyList} roles={["admin", "assistant"]} />,
    },
    {
      path: "/ReviewTrooperInvoice",
      element: (
          <LazyWrapper Component={ReviewTrooperInvoice} roles={["default", "assistant"]} />
      ),
    },
    {
      path: "/jobsList",
        element: <LazyWrapper Component={JobsList} roles={["admin", "assistant"]} />,
    },
    {
      path: "/invoices",
      element: <LazyWrapper Component={Invoices} roles={["admin"]} />,
    },
    {
      path: "/underConstruction",
      element: (
        <LazyWrapper Component={UnderConstruction} roles={["default"]} />
      ),
    },
    {
      path: "/unauthorized",
      element: <LazyWrapper Component={Unauthorized} roles={["default"]} />,
    },
    {
      path: "/pageNotFound",
      element: <LazyWrapper Component={PageNotFound404} roles={["default"]} />,
    },
  ]);

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={route} />
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
