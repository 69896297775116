import { configureStore } from "@reduxjs/toolkit";
import assignmentsReducer from "./assignmentsSlice";
import userReducer from "./userSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    assignments: assignmentsReducer,
  },
});
