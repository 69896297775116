import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const selectAssignments = (state) => state.assignments;
const selectAssignmentsByCounty = (state) =>
  state.assignments.assignmentsByCounty;
const selectJobAssignmentCounts = (state) =>
  state.assignments.jobAssignmentCounts;

const loadStateFromStorage = () => {
  try {
    const serializedState = localStorage.getItem("jobAssignments");
    const serializedCounts = localStorage.getItem("jobAssignmentCounts");
    return {
      assignmentsByCounty: serializedState ? JSON.parse(serializedState) : {},
      jobAssignmentCounts: serializedCounts ? JSON.parse(serializedCounts) : {},
    };
  } catch (err) {
    console.error("Error loading state:", err);
    return {
      assignmentsByCounty: {},
      jobAssignmentCounts: {},
    };
  }
};

const saveStateToStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state.assignmentsByCounty);
    const serializedCounts = JSON.stringify(state.jobAssignmentCounts);
    localStorage.setItem("jobAssignments", serializedState);
    localStorage.setItem("jobAssignmentCounts", serializedCounts);
  } catch (err) {
    console.error("Error saving state:", err);
  }
};

const calculateJobAssignments = (assignments) => {
  const counts = {};
  Object.values(assignments).forEach((jobIds) => {
    jobIds.forEach((jobId) => {
      counts[jobId] = (counts[jobId] || 0) + 1;
    });
  });
  return counts;
};

// Memoized selector for specific county assignments
export const selectCountyAssignments = createSelector(
  [selectAssignmentsByCounty, (_, countyId) => countyId],
  (assignmentsByCounty, countyId) => assignmentsByCounty[countyId] || {}
);

// Memoized selector for specific county job counts
export const selectCountyJobCounts = createSelector(
  [selectJobAssignmentCounts, (_, countyId) => countyId],
  (jobAssignmentCounts, countyId) => jobAssignmentCounts[countyId] || {}
);

const assignmentsSlice = createSlice({
  name: "assignments",
  initialState: loadStateFromStorage(),
  reducers: {
    updateCountyAssignments: (state, action) => {
      const { countyId, assignments, jobId, assignmentCount } = action.payload;
      state.assignmentsByCounty[countyId] = assignments;

      // Update job assignment counts
      if (!state.jobAssignmentCounts[countyId]) {
        state.jobAssignmentCounts[countyId] = {};
      }

      if (jobId && assignmentCount !== undefined) {
        state.jobAssignmentCounts[countyId][jobId] = assignmentCount;
      } else {
        // Recalculate all counts for the county
        state.jobAssignmentCounts[countyId] =
          calculateJobAssignments(assignments);
      }

      saveStateToStorage(state);
    },
    removeJobAssignment: (state, action) => {
      const { countyId, trooperId, jobId } = action.payload;
      if (state.assignmentsByCounty[countyId]?.[trooperId]) {
        state.assignmentsByCounty[countyId][trooperId] =
          state.assignmentsByCounty[countyId][trooperId].filter(
            (id) => id !== jobId
          );

        // Update job assignment count
        if (state.jobAssignmentCounts[countyId]?.[jobId]) {
          state.jobAssignmentCounts[countyId][jobId]--;
          if (state.jobAssignmentCounts[countyId][jobId] === 0) {
            delete state.jobAssignmentCounts[countyId][jobId];
          }
        }

        saveStateToStorage(state);
      }
    },
  },
});

export const { updateCountyAssignments, removeJobAssignment } =
  assignmentsSlice.actions;
export default assignmentsSlice.reducer;
