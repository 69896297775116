import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersId: null,
  userFName: "",
  userLName: "",
  userRole: "",
  emailAddress: "",
  phone: "",
  perm: "",
  address: "",
  myWork: [],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    clearUser: (state) => {
      return initialState;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
